import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
    <footer className="footer bg-dark text-white border-top border-warning border-3">
        <Container>
            <Row className="d-none">
                <Col md={4}>
                    <h5>About DroneMapper</h5>
                    <p>Join us in creating custom waypoint missions that can be imported to DJI drones.</p>
                </Col>
                <Col md={2} className="col-6">
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="/jargon#jargon-about" className="text-white">About Us</a></li>
                        <li><a href="/jargon#jargon-contact" className="text-white">Contact Us</a></li>
                        <li><a href="/jargon#jargon-terms" className="text-white">Terms of Service</a></li>
                        <li><a href="/jargon#jargon-privacy" className="text-white">Privacy Policy</a></li>
                    </ul>
                </Col>
                <Col md={2} className="col-6">
                    <h5>Nav Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="/splat" className="text-white">Photo Party</a></li>
                        <li><a href="/potato" className="text-white">Photo Potato</a></li>
                        <li><a href="/profile" className="text-white">User Profile</a></li>
                        <li><a href="https://redradar.dev" className="text-white">RedRadar</a></li>
                    </ul>
                </Col>
                <Col md={4}>
                    <h5>Contact Info</h5>
                    <p>Email: support@waypointwiz.com <br />
                        Phone: +123 456 7890 <br />
                        Address: 123 Party St., Celebration City, PP 12345</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-3">
                    <p>&copy; {new Date().getFullYear()} WaypointWiz. All rights reserved.</p>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;