

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Alert, Button, DropdownButton, Dropdown, ListGroup } from 'react-bootstrap';
import JSZip from 'jszip';
import xml2js from 'xml2js';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const KMZUploader = () => {
  const [fileContent, setFileContent] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [records, setRecords] = useState({});
  const [selectedRecordId, setSelectedRecordId] = useState('');
  const [missionName, setMissionName] = useState('dji-mission');

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}`);
      dbRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          setRecords({ ...snapshot.val() });
        } else {
          setRecords({});
        }
      });
    }

    return () => {
      if (user) {
        firebase.database().ref(`/kmzRecords/${user.uid}`).off();
      }
    };
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      JSZip.loadAsync(file)
        .then((zip) => {
          const wpmlFile = zip.file(/\.wpml$/i)[0];
          if (wpmlFile) {
            wpmlFile.async("string").then((content) => {
              setFileContent(content);
              setUploadError('');
            });
          } else {
            setUploadError('No WPML file found in the KMZ archive.');
            setFileContent('');
          }
        })
        .catch(() => {
          setUploadError('Error reading KMZ file.');
          setFileContent('');
        });
    }
  };

  const saveRecordToFirebase = () => {
    const user = firebase.auth().currentUser;
    if (user && fileContent) {
      const timestamp = new Date().toISOString();
      const parser = new xml2js.Parser();
      parser.parseString(fileContent, (err, result) => {
        if (err) {
          console.error("Error parsing WPML file: ", err);
          return;
        }
        // Assuming the Placemark data is within result.kml.Document.Folder
        const placemarks = result.kml.Document[0].Folder[0].Placemark;
  
        // Process each Placemark to extract details
        const waypoints = placemarks.map((placemark, index) => {
          // Extract and structure the data from each Placemark
          const coordinates = placemark.Point[0].coordinates[0].trim();
          const [longitude, latitude] = coordinates.split(',');
          // Adjust the data extraction based on the WPML file structure
  
          // Return a structured object for each waypoint
          return {
            index: parseInt(placemark['wpml:index'][0]),
            coordinateLat: latitude,
            coordinateLong: longitude,
            executeHeight: parseFloat(placemark['wpml:executeHeight'][0]),
            waypointSpeed: parseFloat(placemark['wpml:waypointSpeed'][0]),
            waypointHeadingMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingMode'][0],
            waypointHeadingAngle: parseFloat(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngle'][0]),
            waypointPoiPoint: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointPoiPoint'][0],
            waypointHeadingAngleEnable: parseInt(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngleEnable'][0]),
            waypointHeadingPathMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingPathMode'][0],
            waypointTurnMode: placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnMode'][0],
            waypointTurnDampingDist: parseFloat(placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnDampingDist'][0]),
            useStraightLine: placemark['wpml:useStraightLine'][0] === '1',
            actions: placemark['wpml:actionGroup'] ? placemark['wpml:actionGroup'].map(actionGroup => ({
              actionGroupId: parseInt(actionGroup['wpml:actionGroupId'][0]),
              actionGroupStartIndex: parseInt(actionGroup['wpml:actionGroupStartIndex'][0]),
              actionGroupEndIndex: parseInt(actionGroup['wpml:actionGroupEndIndex'][0]),
              actionGroupMode: actionGroup['wpml:actionGroupMode'][0],
              actionTriggerType: actionGroup['wpml:actionTrigger'][0]['wpml:actionTriggerType'][0],
              actionId: parseInt(actionGroup['wpml:action'][0]['wpml:actionId'][0]),
              actionActuatorFunc: actionGroup['wpml:action'][0]['wpml:actionActuatorFunc'][0],
              // Add other action parameters as needed
            })) : []
          };
        });
  
        // Save the structured data to Firebase
      const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}`);
      const newRecordRef = dbRef.push();
      newRecordRef.set({ missionName, timestamp, waypoints })
        .then(() => console.log("Record saved successfully"))
        .catch(error => console.error("Error saving record: ", error));
    });
  } else {
    console.error("User is not authenticated or file content is empty");
  }
};

const renderWaypointsToXML = (waypoints) => {
    return waypoints.map(wp => {
        const poiPoint = wp.waypointPoiPoint || '0.000000,0.000000,0.000000';
        const gimbalParams = wp.actions.map(action => {
            let actionParams = '';
            if (action.actionGroupId === 1) {
                actionParams = 
                `<wpml:gimbalHeadingYawBase>${action.gimbalHeadingYawBase || 'aircraft'}</wpml:gimbalHeadingYawBase>\n` +
                `<wpml:gimbalRotateMode>${action.gimbalRotateMode || 'absoluteAngle'}</wpml:gimbalRotateMode>\n` +
                `<wpml:gimbalPitchRotateEnable>${action.gimbalPitchRotateEnable || '1'}</wpml:gimbalPitchRotateEnable>\n` +
                `<wpml:gimbalPitchRotateAngle>${action.gimbalPitchRotateAngle || '0'}</wpml:gimbalPitchRotateAngle>\n` +
                `<wpml:gimbalRollRotateEnable>${action.gimbalRollRotateEnable || '0'}</wpml:gimbalRollRotateEnable>\n` +
                `<wpml:gimbalRollRotateAngle>${action.gimbalRollRotateAngle || '0'}</wpml:gimbalRollRotateAngle>\n` +
                `<wpml:gimbalYawRotateEnable>${action.gimbalYawRotateEnable || '0'}</wpml:gimbalYawRotateEnable>\n` +
                `<wpml:gimbalYawRotateAngle>${action.gimbalYawRotateAngle || '0'}</wpml:gimbalYawRotateAngle>\n` +
                `<wpml:gimbalRotateTimeEnable>${action.gimbalRotateTimeEnable || '0'}</wpml:gimbalRotateTimeEnable>\n` +
                `<wpml:gimbalRotateTime>${action.gimbalRotateTime || '0'}</wpml:gimbalRotateTime>\n` +
                `<wpml:payloadPositionIndex>${action.payloadPositionIndex || '0'}</wpml:payloadPositionIndex>\n`;
            } else if (action.actionGroupId === 2) {
                actionParams = 
                `<wpml:gimbalPitchRotateAngle>${action.gimbalPitchRotateAngle || '0'}</wpml:gimbalPitchRotateAngle>\n` +
                `<wpml:payloadPositionIndex>${action.payloadPositionIndex || '0'}</wpml:payloadPositionIndex>\n`;
            }
            return (
                `<wpml:actionGroup>\n` +
                `<wpml:actionGroupId>${action.actionGroupId}</wpml:actionGroupId>\n` +
                `<wpml:actionGroupStartIndex>${action.actionGroupStartIndex}</wpml:actionGroupStartIndex>\n` +
                `<wpml:actionGroupEndIndex>${action.actionGroupEndIndex}</wpml:actionGroupEndIndex>\n` +
                `<wpml:actionGroupMode>${action.actionGroupMode}</wpml:actionGroupMode>\n` +
                `<wpml:actionTrigger>\n` +
                `<wpml:actionTriggerType>${action.actionTriggerType}</wpml:actionTriggerType>\n` +
                `</wpml:actionTrigger>\n` +
                `<wpml:action>\n` +
                `<wpml:actionId>${action.actionId}</wpml:actionId>\n` +
                `<wpml:actionActuatorFunc>${action.actionActuatorFunc}</wpml:actionActuatorFunc>\n` +
                `<wpml:actionActuatorFuncParam>\n` +
                actionParams +
                `</wpml:actionActuatorFuncParam>\n` +
                `</wpml:action>\n` +
                `</wpml:actionGroup>\n`
            );
        }).join('');
        
        return (
            `<Placemark>\n` + 
            `<Point>\n` +
            `<coordinates>\n${wp.coordinateLong},${wp.coordinateLat}\n</coordinates>\n` +
            `</Point>\n` +
            `<wpml:index>${wp.index}</wpml:index>\n` +
            `<wpml:executeHeight>${wp.executeHeight}</wpml:executeHeight>\n` +
            `<wpml:waypointSpeed>${wp.waypointSpeed}</wpml:waypointSpeed>\n` +
            `<wpml:waypointHeadingParam>\n` +
            `<wpml:waypointHeadingMode>${wp.waypointHeadingMode}</wpml:waypointHeadingMode>\n` +
            `<wpml:waypointHeadingAngle>${wp.waypointHeadingAngle}</wpml:waypointHeadingAngle>\n` +
            `<wpml:waypointPoiPoint>${poiPoint}</wpml:waypointPoiPoint>\n` +
            `<wpml:waypointHeadingAngleEnable>${wp.waypointHeadingAngleEnable}</wpml:waypointHeadingAngleEnable>\n` +
            `<wpml:waypointHeadingPathMode>${wp.waypointHeadingPathMode}</wpml:waypointHeadingPathMode>\n` +
            `</wpml:waypointHeadingParam>\n` +
            `<wpml:waypointTurnParam>\n` +
            `<wpml:waypointTurnMode>${wp.waypointTurnMode}</wpml:waypointTurnMode>\n` +
            `<wpml:waypointTurnDampingDist>${wp.waypointTurnDampingDist}</wpml:waypointTurnDampingDist>\n` +
            `</wpml:waypointTurnParam>\n` +
            `<wpml:useStraightLine>${wp.useStraightLine ? '1' : '0'}</wpml:useStraightLine>\n` +
            gimbalParams +
            `</Placemark>\n`
            );
    }).join('\n');
};



const viewRecord = (id) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}/${id}`);
      dbRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setSelectedRecordId(id);
          const record = snapshot.val();
          // Assuming the waypoints data is stored in record
          if (record.waypoints) {
            const header = `<?xml version="1.0" encoding="UTF-8"?>\n` +
                 `<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.2">\n` +
                 `<Document>\n` +
                 `<wpml:missionConfig>\n` +
                 `<wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>\n` +
                 `<wpml:finishAction>noAction</wpml:finishAction>\n` +
                 `<wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>\n` +
                 `<wpml:executeRCLostAction>hover</wpml:executeRCLostAction>\n` +
                 `<wpml:globalTransitionalSpeed>2.5</wpml:globalTransitionalSpeed>\n` +
                 `<wpml:droneInfo>\n` +
                 `<wpml:droneEnumValue>68</wpml:droneEnumValue>\n` +
                 `<wpml:droneSubEnumValue>0</wpml:droneSubEnumValue>\n` +
                 `</wpml:droneInfo>\n` +
                 `</wpml:missionConfig>\n` +
                 `<Folder>\n` +
                 `<wpml:templateId>0</wpml:templateId>\n` +
                 `<wpml:executeHeightMode>relativeToStartPoint</wpml:executeHeightMode>\n` +
                 `<wpml:waylineId>0</wpml:waylineId>\n` +
                 `<wpml:distance>0</wpml:distance>\n` +
                 `<wpml:duration>0</wpml:duration>\n` +
                 `<wpml:autoFlightSpeed>2.5</wpml:autoFlightSpeed>\n`;

            const footer = `\n` +
                `</Folder>\n` +
                `</Document>\n` +
                `</kml>\n`;
            const waypointsContent = renderWaypointsToXML(record.waypoints);
            setFileContent(header + waypointsContent + footer);
          } else {
            setFileContent("No waypoints data found.");
          }
        } else {
          console.error("Record not found");
        }
      }).catch((error) => {
        console.error("Error fetching record: ", error);
      });
    } else {
      console.error("User is not authenticated");
    }
  };
  
  const deleteRecord = (id) => {
    const user = firebase.auth().currentUser;
    if (user) {
      if (window.confirm("Are you sure you want to delete this record?")) {
        firebase.database().ref(`/kmzRecords/${user.uid}/${id}`).remove()
        .then(() => {
          console.log("Record deleted successfully");
          if (id === selectedRecordId) {
            setFileContent('');
            setSelectedRecordId('');
          }
        })
        .catch((error) => {
          console.error("Error deleting record: ", error);
        });
      }
    } else {
      console.error("User is not authenticated");
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12} md={6}>
                <Form>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload KMZ File</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} accept=".kmz" />
                    </Form.Group>
                    <Form.Group controlId="missionName" className="mb-3">
                        <Form.Label>Mission Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={missionName}
                            onChange={(e) => setMissionName(e.target.value)}
                            placeholder="dji-mission"
                        />
                    </Form.Group>
                    {uploadError && <Alert variant="danger">{uploadError}</Alert>}
                    {fileContent && (
                    <Button variant="primary" onClick={saveRecordToFirebase}>
                        Save to Firebase
                    </Button>
                    )}
                </Form>
            </Col>
            <Col xs={12} md={6} className="d-none">
                <h3>Records List</h3>
                <DropdownButton id="dropdown-basic-button" title="Select a Mission">
                    {Object.keys(records).map((id) => (
                    <Dropdown.Item key={id} onClick={() => viewRecord(id)}>
                        {records[id].missionName} - {new Date(records[id].timestamp).toLocaleString()}
                    </Dropdown.Item>
                    ))}
                </DropdownButton>
            </Col>
            <Col xs={12} md={6}>
                <ListGroup>
                    {Object.keys(records).map((id) => (
                    <ListGroup.Item key={id} action onClick={() => viewRecord(id)}>
                        {records[id].missionName} - {new Date(records[id].timestamp).toLocaleString()}
                        <Button variant="danger" size="sm" className="" style={{ float: 'right' }} onClick={(e) => {e.stopPropagation(); deleteRecord(id);}}>Delete</Button>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            </Col>
        </Row>
        </Col>
        <Col xs={12}>
          {fileContent && (
            <>
              <h5>WPML File Content:</h5>
              <pre><code>{fileContent}</code></pre>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default KMZUploader;