import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert, InputGroup, FormControl, DropdownButton, Dropdown, Table, Card, ButtonGroup, ListGroup } from 'react-bootstrap';
import { GoogleMap, LoadScript, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import RangeSlider from 'react-bootstrap-range-slider';
import JSZip from 'jszip';
import xml2js from 'xml2js';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import directionalArrow from './assets/arrow.png'
import tempArrow from './assets/drone.png'

const WaypointForm = () => {
  const [waypoint, setWaypoint] = useState({
    coordinateLat: '0.000000',
    coordinateLong: '0.000000',
    index: 0,
    executeHeight: 60,
    waypointSpeed: 2.5,
    waypointHeadingMode: 'smoothTransition',
    waypointHeadingAngle: 0,
    waypointPoiPoint: '0.000000,0.000000,0.000000',
    waypointPoiPointLat: '0.000000',
    waypointPoiPointLong: '0.000000',
    waypointPoiPointEle: '0.00',
    waypointHeadingAngleEnable: 1,
    waypointHeadingPathMode: 'followBadArc',
    waypointTurnMode: 'toPointAndStopWithContinuityCurvature',
    waypointTurnDampingDist: 0,
    useStraightLine: false,
    gimbalPitchRotateAngle: -45,
    actions: [
      {
        actionGroupId: 1,
        actionGroupStartIndex: 0,
        actionGroupEndIndex: 0,
        actionGroupMode: 'parallel',
        actionTriggerType: 'reachPoint',
        actionId: 1,
        actionActuatorFunc: 'gimbalRotate',
        gimbalHeadingYawBase: 'aircraft',
        gimbalRotateMode: 'absoluteAngle',
        gimbalPitchRotateEnable: 1,
        // gimbalPitchRotateAngle: -45,
        gimbalRollRotateEnable: 0,
        gimbalRollRotateAngle: 0,
        gimbalYawRotateEnable: 0,
        gimbalYawRotateAngle: 0,
        gimbalRotateTimeEnable: 0,
        gimbalRotateTime: 0,
        payloadPositionIndex: 0,
      },
      // Add more actions as needed
    ],
  });
  const [waypoints, setWaypoints] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [newMarker, setNewMarker] = useState(null);
  const [tempPoiMarker, setTempPoiMarker] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [tempMarkerRotation, setTempMarkerRotation] = useState(null);
  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [map, setMap] = useState(null);
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState(null);
  const [selectedMissionId, setSelectedMissionId] = useState(null);
  const [progressState, setProgressState] = useState('Waiting for Location - Click the Map to Start');
  const [currentMission, setCurrentMission] = useState(null);
  const [currentMissionId, setCurrentMissionId] = useState(null);
  const [missionWaypoints, setMissionWaypoints] = useState([]);
  const [records, setRecords] = useState({});
  const [fileContent, setFileContent] = useState('');
  const [selectedRecordId, setSelectedRecordId] = useState('');
  const [missionName, setMissionName] = useState('my-mission');
  const [uploadError, setUploadError] = useState('');
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markerInfo, setMarkerInfo] = useState({ yawAngle: 0, elevation: 0 });
  const [elevation, setElevation] = useState(null);
  const currentUserUid = firebase.auth().currentUser?.uid;
  // const [settingPoi, setSettingPoi] = useState(false);
  const mapsAPIKey = 'process.env.WPWIZ_GOOGLE_MAPS_API_KEY'
  
  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  
  const containerStyle = {width: '100%', height: '100%', minHeight: '720px'};
  // const center = {lat: 32.997242, lng: -117.085975 };
  {/* const getElevation = async (lat, lng) => {
    const elevationUrl = `https://maps.googleapis.com/maps/api/elevation/json?locations=${lat},${lng}&key=${mapsAPIKey}`;
    try {
      const response = await fetch(elevationUrl);
      const data = await response.json();
      return data.results[0].elevation;
    } catch (error) {
      console.error('Error fetching elevation data:', error);
      return 0;
    }
  };

  const fetchElevation = async (lat, lng) => {
    const elevationApiUrl = `https://maps.googleapis.com/maps/api/elevation/json?locations=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    try {
      const response = await fetch(elevationApiUrl);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].elevation; // meters
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching elevation:', error);
      return null;
    }
  }; */}

  const onMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
  
    if (clickCount === 0) {
      setProgressState('Waiting for Heading / Point of Interest - Click to Continue');
      // First click sets the waypoint coordinates
      setNewMarker({ lat, lng });
      setWaypoint({
        ...waypoint,
        coordinateLat: lat.toString(),
        coordinateLong: lng.toString()
      });
      // const elevation = await getElevation(lat, lng);
      // setMarkerInfo({ ...markerInfo, elevation });
      // Check if the current heading mode requires a second click
      if (waypoint.waypointHeadingMode === 'smoothTransition' || waypoint.waypointHeadingMode === 'towardPOI') {
        setClickCount(1);
      } else {
        // For other modes, immediately confirm the waypoint
        setProgressState('Completed - Confirm adding this waypoint');
        // handleShowConfirmationModal();
        setShowConfirmationPrompt(true); // Show confirmation prompt directly
      }
    } else if (clickCount === 1) {
      setProgressState('Completed - Confirm adding this waypoint'); // Update progress state
      // Handle second click for specific waypoint heading modes
      if (waypoint.waypointHeadingMode === 'smoothTransition') {
        const angle = calculateYawAngle({ lat: parseFloat(waypoint.coordinateLat), lng: parseFloat(waypoint.coordinateLong) }, { lat, lng });
        setWaypoint({
          ...waypoint,
          waypointHeadingAngle: angle
        });
        setTempMarkerRotation(angle); // Set temporary marker rotation
      } else if (waypoint.waypointHeadingMode === 'towardPOI') {
        setWaypoint({
          ...waypoint,
          waypointPoiPointLat: lat.toString(),
          waypointPoiPointLong: lng.toString()
        });
        setTempPoiMarker({ lat, lng }); // Set a temporary POI marker
      }
      setClickCount(0);
      // handleShowConfirmationModal();
      setShowConfirmationPrompt(true); // Show confirmation prompt directly
    }
  };
    
    const calculateYawAngle = (currentLocation, newMarker) => {
        const deltaY = newMarker.lat - currentLocation.lat;
        const deltaX = newMarker.lng - currentLocation.lng;
        let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        angle = (angle + 360) % 360; // Normalize angle to be between 0 and 360 degrees
        angle = (450 - angle) % 360; // Adjust so that 0 degrees is North and angles increase clockwise
        return angle;
    };
    
    const handleConfirmSubmit = () => {
        // setShowConfirmationModal(false);
        setShowConfirmationPrompt(false); // Show confirmation prompt directly
        handleSubmit();
        setProgressState('Waiting for Location - Click the Map to Start'); // Reset progress state
    };

    const handleModeChange = (mode) => {
        setWaypoint({ ...waypoint, waypointHeadingMode: mode });
      };
      
    const calculatePOIAngle = (wp, poi) => {
        if (!poi) return 0; // Return default angle if POI is not set
        
        const deltaY = poi.lat - wp.lat;
        const deltaX = poi.lng - wp.lng;
        let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        angle = (angle + 360) % 360; // Normalize angle to be between 0 and 360 degrees
        angle = (450 - angle) % 360; // Adjust so that 0 degrees is North and angles increase clockwise
        console.log("POI Angle: ", angle); // Debugging angle calculation
        return angle;
    };

    const renderMarkerIcon = (wp, isTemporary = false) => {
        let iconUrl = isTemporary ? directionalArrow : null;
      
        // Define a base object for the icon
        let iconBase = {
          url: iconUrl,
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 16)
        };
      
        if (wp.waypointHeadingMode === 'smoothTransition') {
          const rotationAngle = tempMarkerRotation;
          return {
            ...iconBase,
            rotation: rotationAngle,
            onClick: async () => {
                // const elev = await fetchElevation(parseFloat(wp.coordinateLat), parseFloat(wp.coordinateLong));
                // setElevation(elev);
                setSelectedMarker({ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) });
                setMarkerInfo({ yawAngle: wp.waypointHeadingAngle, elevation: wp.elevation || 0 });
            }
          };
        } else if (wp.waypointHeadingMode === 'towardPOI') {
          const angle = calculatePOIAngle({ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) }, tempPoiMarker);
          return {
            ...iconBase,
            rotation: angle,
            onClick: async () => {
                // const elev = await fetchElevation(parseFloat(wp.coordinateLat), parseFloat(wp.coordinateLong));
                // setElevation(elev);
                setSelectedMarker({ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) });
                setMarkerInfo({ yawAngle: wp.waypointHeadingAngle, elevation: wp.elevation || 0 });
            }
          };
        } else {
          // Return default marker icon without rotation
          return {
            ...iconBase,
            onClick: async () => {
                // const elev = await fetchElevation(parseFloat(wp.coordinateLat), parseFloat(wp.coordinateLong));
                // setElevation(elev);
                setSelectedMarker({ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) });
                setMarkerInfo({ yawAngle: wp.waypointHeadingAngle, elevation: wp.elevation || 0 });
            }
          };
        }
      };
      

    const mapStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }] // Hides points of interest labels
        },
        // Add more style rules as needed
        ];


  useEffect(() => {
    const newMissionId = uuidv4();
    setCurrentMissionId(newMissionId);
    getAllWaypointMissions(); // Fetch all missions when component mounts
    navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
        setCurrentLocation({
            lat: coords.latitude,
            lng: coords.longitude
        });
        },
        (err) => {
        console.error(err);
        },
        { enableHighAccuracy: true }
    );
    }, []);

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
          const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}`);
          dbRef.on('value', (snapshot) => {
            if (snapshot.val() != null) {
              setRecords({ ...snapshot.val() });
            } else {
              setRecords({});
            }
          });
        }
    
        return () => {
          if (user) {
            firebase.database().ref(`/kmzRecords/${user.uid}`).off();
          }
        };
      }, []);

      useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
          const waypointMissionsRef = firebase.database().ref(`/waypointMissions/${user.uid}`);
          waypointMissionsRef.on('value', (snapshot) => {
            if (snapshot.val() != null) {
              const missionsData = snapshot.val();
              const updatedMissions = Object.keys(missionsData).map(key => ({
                id: key,
                ...missionsData[key]
              }));
              setMissions(updatedMissions.filter(mission => mission.userId === currentUserUid)); // Filter by current user's ID
            } else {
              setMissions([]);
            }
          });
    
          return () => {
            waypointMissionsRef.off(); // Detach listener on unmount
        };
      }
    }, [firebase.auth().currentUser, currentUserUid]);

    useEffect(() => {
        if (selectedMissionId) {
          getSelectedMission(); // Call when selectedMissionId changes
        }
      }, [selectedMissionId]);

    const handleNewMission = () => {
        setWaypoints([]);
        setNewMarker(null);
        setTempPoiMarker(null);
        // Generate a new UUID for a new mission
        setCurrentMissionId(uuidv4());
        // Reset any other states as needed
    };

    const getSelectedMission = async () => {
        const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}/${selectedMissionId}`);
        const snapshot = await dbRef.once('value');
        if (snapshot.exists()) {
          const missionData = snapshot.val();
          setSelectedMission(missionData); // Set the selected mission
          setWaypoints(missionData.waypoints || []); // Update waypoints
        }
      };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setWaypoint({ ...waypoint, [name]: type === 'checkbox' ? checked : value });
  };

  const setPointOfInterestCord = () => {
    // setSettingPoi(true); // Enable setting PoI mode
  };

  // const waypointCoords = waypoints.map((wp, index) => ({
  //   label: `Waypoint ${index + 1}: ${wp.coordinateLat}, ${wp.coordinateLong}`,
  //   value: { lat: wp.coordinateLat, lng: wp.coordinateLong }
  // }));

  const getPath = () => {
    return waypoints.map(wp => ({
      lat: parseFloat(wp.coordinateLat),
      lng: parseFloat(wp.coordinateLong)
    }));
  };

    // Function to add a new waypoint mission
    {/* const createWaypointMission = async (waypointMission) => {
        if (!currentUserUid || !currentMissionId) {
          console.error("User is not authenticated or mission ID is not set");
          return;
        }
        // const missionId = uuidv4();
        const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}/${currentMissionId}`);
        await dbRef.set(waypointMission)
            .catch(error => {
            console.error("Error creating mission:", error);
            });
      
        // Reset the waypoints and other relevant states
        setWaypoints([]);
        setNewMarker(null);
        setTempPoiMarker(null);
        setCurrentMissionId(uuidv4());
        // Reset any other states as needed
      }; */}

    // Updated the updateWaypointMission function to include missionName, timestamp and date
    const updateWaypointMission = (missionId, updatedWaypoint, missionName) => {
        if (!currentUserUid || !missionId) {
            console.error("User is not authenticated or invalid mission ID");
            return;
        }

        const timestamp = new Date().toISOString(); // Current timestamp
        // const date = timestamp.split('T')[0]; // Extract date from timestamp

        // Ensure updatedWaypoint is an array
        const waypointsArray = Array.isArray(updatedWaypoint) ? updatedWaypoint : [updatedWaypoint];
        const waypointsWithUUID = waypointsArray.map(wp => ({ id: uuidv4(), ...wp }));

        const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}/${missionId}`);
        dbRef.update({ 
            waypoints: waypointsWithUUID,
            missionName: missionName || 'my-mission',
            timestamp: timestamp,
            // date: date
        })
        .catch(error => {
            console.error("Error updating mission:", error);
        });
    };

    {/* // Function to view all waypoint missions
    const getAllWaypointMissions = async () => {
        if (!currentUserUid) {
          console.error("User is not authenticated");
          return;
        }
        try {
            const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}`);
            const snapshot = await dbRef.once('value');
            if (snapshot.exists()) {
                const missionsData = snapshot.val();
                const missionsList = Object.keys(missionsData).map(key => ({
                    id: key,
                    ...missionsData[key]
                }));
                setMissions(missionsList);
            }
        } catch (error) {
            console.error("Error fetching missions:", error);
        }
    }; */}

    // Function to view all waypoint missions
    const getAllWaypointMissions = async () => {
        if (!currentUserUid) {
          console.error("User is not authenticated");
          return;
        }
        try {
            const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}`);
            const snapshot = await dbRef.once('value');
            if (snapshot.exists()) {
                const missionsData = snapshot.val();
                const missionsList = Object.keys(missionsData).map(key => ({
                    id: key,
                    ...missionsData[key]
                }));
                setMissions(missionsList);
            }
        } catch (error) {
            console.error("Error fetching missions:", error);
        }
    };

    // Function to view a single waypoint mission
    const getWaypointMission = async () => {
        if (!currentUserUid || !selectedMissionId) {
          console.error("User is not authenticated or invalid mission ID");
          return;
        }
        try {
            const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}/${selectedMissionId}`);
            const snapshot = await dbRef.once('value');
            if (snapshot.exists()) {
                const missionData = snapshot.val();
                setCurrentMission(missionData);
    
                // Assuming the mission data contains waypoints
                if (missionData.waypoints) {
                    setMissionWaypoints(missionData.waypoints);
                }
            } else {
                console.log("No mission data found for the given ID");
                setCurrentMission(null);
                setMissionWaypoints([]);
            }
        } catch (error) {
            console.error("Error fetching mission:", error);
        }
    };

    // Function to delete a waypoint mission
    const deleteWaypointMission = (missionId) => {
        if (!currentUserUid || !missionId) {
          console.error("User is not authenticated or invalid mission ID");
          return;
        }
        const dbRef = firebase.database().ref(`/waypointMissions/${currentUserUid}/${missionId}`);
        dbRef.remove();
    };

      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          JSZip.loadAsync(file)
            .then((zip) => {
              const wpmlFile = zip.file(/\.wpml$/i)[0];
              if (wpmlFile) {
                wpmlFile.async("string").then((content) => {
                  setFileContent(content);
                  parseAndSetWaypoints(content); // Function to parse content and set waypoints
                  setUploadError('');
                });
              } else {
                setUploadError('No WPML file found in the KMZ archive.');
                setFileContent('');
              }
            })
            .catch(() => {
              setUploadError('Error reading KMZ file.');
              setFileContent('');
            });
        }
      };
    
      const parseAndSetWaypoints = (content) => {
        const parser = new xml2js.Parser();
        parser.parseString(content, (err, result) => {
          if (err) {
            console.error("Error parsing WPML file: ", err);
            return;
          }
          // Extract waypoints data from the parsed content
          const placemarks = result.kml.Document[0].Folder[0].Placemark;
          const waypoints = placemarks.map((placemark) => {
            // Extract and structure the data from each Placemark
            const coordinates = placemark.Point[0].coordinates[0].split(',');
            const [longitude, latitude] = coordinates;
            // Return a structured object for each waypoint
            return {
              coordinateLat: latitude.trim(),
              coordinateLong: longitude.trim(),
              index: parseInt(placemark['wpml:index'][0]),
              executeHeight: parseFloat(placemark['wpml:executeHeight'][0]),
              waypointSpeed: parseFloat(placemark['wpml:waypointSpeed'][0]),
              waypointHeadingMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingMode'][0],
              waypointHeadingAngle: parseFloat(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngle'][0]),
              waypointPoiPoint: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointPoiPoint'][0],
              waypointHeadingAngleEnable: parseInt(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngleEnable'][0]),
              waypointHeadingPathMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingPathMode'][0],
              waypointTurnMode: placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnMode'][0],
              waypointTurnDampingDist: parseFloat(placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnDampingDist'][0]),
              useStraightLine: placemark['wpml:useStraightLine'][0] === '1',
              gimbalPitchRotateAngle: parseFloat(placemark['wpml:actionGroup'][0]['wpml:action'][0]['wpml:actionActuatorFuncParam'][0]['wpml:gimbalPitchRotateAngle'][0]),
              actions: placemark['wpml:actionGroup'].map(actionGroup => {
                return {
                  actionGroupId: parseInt(actionGroup['wpml:actionGroupId'][0]),
                  actionGroupStartIndex: parseInt(actionGroup['wpml:actionGroupStartIndex'][0]),
                  actionGroupEndIndex: parseInt(actionGroup['wpml:actionGroupEndIndex'][0]),
                  actionGroupMode: actionGroup['wpml:actionGroupMode'][0],
                  actionTriggerType: actionGroup['wpml:actionTrigger'][0]['wpml:actionTriggerType'][0],
                  actionId: parseInt(actionGroup['wpml:action'][0]['wpml:actionId'][0]),
                  actionActuatorFunc: actionGroup['wpml:action'][0]['wpml:actionActuatorFunc'][0],
                  // Add other action parameters as needed
                };
              }),
              // ... [other waypoint properties as needed]
            };
          });
      
          setWaypoints(waypoints); // Set the extracted waypoints to state
        });
      };
    
      const saveRecordToFirebase = () => {
        const user = firebase.auth().currentUser;
        if (user && fileContent) {
          const timestamp = new Date().toISOString();
          const parser = new xml2js.Parser();
          parser.parseString(fileContent, (err, result) => {
            if (err) {
              console.error("Error parsing WPML file: ", err);
              return;
            }
            // Assuming the Placemark data is within result.kml.Document.Folder
            const placemarks = result.kml.Document[0].Folder[0].Placemark;
      
            // Process each Placemark to extract details
            const waypoints = placemarks.map((placemark, index) => {
              // Extract and structure the data from each Placemark
              const coordinates = placemark.Point[0].coordinates[0].trim();
              const [longitude, latitude] = coordinates.split(',');
              // Adjust the data extraction based on the WPML file structure
      
              // Return a structured object for each waypoint
              return {
                index: parseInt(placemark['wpml:index'][0]),
                coordinateLat: latitude,
                coordinateLong: longitude,
                executeHeight: parseFloat(placemark['wpml:executeHeight'][0]),
                waypointSpeed: parseFloat(placemark['wpml:waypointSpeed'][0]),
                waypointHeadingMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingMode'][0],
                waypointHeadingAngle: parseFloat(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngle'][0]),
                waypointPoiPoint: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointPoiPoint'][0],
                waypointHeadingAngleEnable: parseInt(placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingAngleEnable'][0]),
                waypointHeadingPathMode: placemark['wpml:waypointHeadingParam'][0]['wpml:waypointHeadingPathMode'][0],
                waypointTurnMode: placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnMode'][0],
                waypointTurnDampingDist: parseFloat(placemark['wpml:waypointTurnParam'][0]['wpml:waypointTurnDampingDist'][0]),
                useStraightLine: placemark['wpml:useStraightLine'][0] === '1',
                actions: placemark['wpml:actionGroup'] ? placemark['wpml:actionGroup'].map(actionGroup => ({
                  actionGroupId: parseInt(actionGroup['wpml:actionGroupId'][0]),
                  actionGroupStartIndex: parseInt(actionGroup['wpml:actionGroupStartIndex'][0]),
                  actionGroupEndIndex: parseInt(actionGroup['wpml:actionGroupEndIndex'][0]),
                  actionGroupMode: actionGroup['wpml:actionGroupMode'][0],
                  actionTriggerType: actionGroup['wpml:actionTrigger'][0]['wpml:actionTriggerType'][0],
                  actionId: parseInt(actionGroup['wpml:action'][0]['wpml:actionId'][0]),
                  actionActuatorFunc: actionGroup['wpml:action'][0]['wpml:actionActuatorFunc'][0],
                  // Add other action parameters as needed
                })) : []
              };
            });
      
            // Save the structured data to Firebase
          const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}`);
          const newRecordRef = dbRef.push();
          newRecordRef.set({ missionName, timestamp, waypoints })
            .then(() => console.log("Record saved successfully"))
            .catch(error => console.error("Error saving record: ", error));
        });
      } else {
        console.error("User is not authenticated or file content is empty");
      }
    };

  const handleSubmit = (e) => {
    // Prevent form submission if it's called directly
    if (e) e.preventDefault();
    const poiPoint = `${waypoint.waypointPoiPointLat},${waypoint.waypointPoiPointLong},${waypoint.waypointPoiPointEle}`;
    const newWaypoint = {
      ...waypoint,
      waypointPoiPoint: poiPoint,
      // Preserve the existing waypointHeadingMode instead of resetting it
      waypointHeadingMode: waypoint.waypointHeadingMode
    };
  
    if (editIndex >= 0) {
      const updatedWaypoints = [...waypoints];
      updatedWaypoints[editIndex] = newWaypoint;
      setWaypoints(updatedWaypoints);
      setEditIndex(-1);
    } else {
      setWaypoints([...waypoints, newWaypoint]);
    }
    setWaypoint({
      // Reset to initial values or define a function to reset to default values
    coordinateLat: '0.000000',
    coordinateLong: '0.000000',
    index: waypoints.length + 1,
    executeHeight: 60,
    waypointSpeed: 2.5,
    waypointHeadingMode: waypoint.waypointHeadingMode, // Keep the current heading mode
    waypointHeadingAngle: 0,
    waypointPoiPoint: '0.000000,0.000000,0.000000',
    waypointPoiPointLat: '0.000000',
    waypointPoiPointLong: '0.000000',
    waypointPoiPointEle: '0.00',
    waypointHeadingAngleEnable: 1,
    waypointHeadingPathMode: 'followBadArc',
    waypointTurnMode: 'toPointAndStopWithContinuityCurvature',
    waypointTurnDampingDist: 0,
    gimbalPitchRotateAngle: -45,
    useStraightLine: false,
        actions: [
        {
            actionGroupId: 1,
            actionGroupStartIndex: 0,
            actionGroupEndIndex: 0,
            actionGroupMode: 'parallel',
            actionTriggerType: 'reachPoint',
            actionId: 1,
            actionActuatorFunc: 'gimbalRotate',
            gimbalHeadingYawBase: 'aircraft',
            gimbalRotateMode: 'absoluteAngle',
            gimbalPitchRotateEnable: 1,
            // gimbalPitchRotateAngle: -45,
            gimbalRollRotateEnable: 0,
            gimbalRollRotateAngle: 0,
            gimbalYawRotateEnable: 0,
            gimbalYawRotateAngle: 0,
            gimbalRotateTimeEnable: 0,
            gimbalRotateTime: 0,
            payloadPositionIndex: 0,
        },
        // Add more actions as needed
        ],
      // ... [Rest of the initial values]
    });
    // setShowModal(false);
    // Reset temporary markers
    setNewMarker(null);
    setTempPoiMarker(null);
    setProgressState('Waiting for Location - Click the Map to Start'); // Reset progress state
  };

  const resetForm = () => {
    setWaypoint({
        coordinateLat: '0.000000',
        coordinateLong: '0.000000',
        index: 0,
        executeHeight: 60,
        waypointSpeed: 2.5,
        waypointHeadingMode: 'smoothTransition',
        waypointHeadingAngle: 0,
        waypointPoiPoint: '0.000000,0.000000,0.000000',
        waypointPoiPointLat: '0.000000',
        waypointPoiPointLong: '0.000000',
        waypointPoiPointEle: '0.00',
        waypointHeadingAngleEnable: 1,
        waypointHeadingPathMode: 'followBadArc',
        waypointTurnMode: 'toPointAndStopWithContinuityCurvature',
        waypointTurnDampingDist: 0,
        useStraightLine: false,
        gimbalPitchRotateAngle: -45,
        actions: [/* ...initial actions... */],
    });
    setNewMarker(null);
    setTempPoiMarker(null);
    setClickCount(0);
    setTempMarkerRotation(null);
    setShowConfirmationPrompt(false);
    // Any other state you want to reset
    setProgressState('Waiting for Location - Click the Map to Start'); // Reset progress state
};

  const handleEdit = (index) => {
    setWaypoint({ ...waypoints[index] });
    setEditIndex(index);
    // setShowModal(true);
  };

  // const handlePoiChange = (e) => {
  //   const { name, value } = e.target;
  //   setWaypoint({
  //     ...waypoint,
  //     [name]: value,
  //     waypointPoiPoint: `${waypoint.waypointPoiPointLat},${waypoint.waypointPoiPointLong},${waypoint.waypointPoiPointEle}`
  //   });
  // };

  const handleDelete = (index) => {
    const newWaypoints = waypoints.filter((_, i) => i !== index);
    setWaypoints(newWaypoints);
  };

  const sortedWaypoints = waypoints.sort((a, b) => a.index - b.index);

  class CustomOverlay extends (window.google ? window.google.maps.OverlayView : Object) {
    constructor(position, content) {
      super();
      this.position = position;
      this.content = content;
      this.div = null;
    }

    onAdd() {
        this.div = document.createElement('div');
        this.div.style.position = 'absolute';
        this.div.style.zIndex = '1000'; // Set a high zIndex to render above markers
        this.div.innerHTML = this.content;
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      }

    draw() {
      const overlayProjection = this.getProjection();
      const position = overlayProjection.fromLatLngToDivPixel(this.position);
      // Adjust x and y to anchor the overlay center to the marker
      const xOffset = -16; // Half the width of the image
      const yOffset = -16; // Half the height of the image
      this.div.style.left = (position.x + xOffset) + 'px';
      this.div.style.top = (position.y + yOffset) + 'px';
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  }

  const createRotatedMarker = (position, angle) => {
    if (!map) return; // Make sure the map is loaded
    const iconHtml = `<img src="${tempArrow}" style="transform: rotate(${angle}deg); width: 32px; height: 32px;">`;
    const customOverlay = new CustomOverlay(position, iconHtml);
    customOverlay.setMap(map);
  };

  const modeMapping = [
    'coordinateTurn',
    'toPointAndStopWithDiscontinuityCurvature',
    'toPointAndStopWithContinuityCurvature',
    'toPointAndPassWithContinuityCurvature'
  ];

    const deleteRecord = (id) => {
        const user = firebase.auth().currentUser;
        if (user) {
          if (window.confirm("Are you sure you want to delete this record?")) {
            firebase.database().ref(`/kmzRecords/${user.uid}/${id}`).remove()
            .then(() => {
              console.log("Record deleted successfully");
              if (id === selectedRecordId) {
                setFileContent('');
                setSelectedRecordId('');
              }
            })
            .catch((error) => {
              console.error("Error deleting record: ", error);
            });
          }
        } else {
          console.error("User is not authenticated");
        }
      };

  const viewRecord = (id) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const dbRef = firebase.database().ref(`/kmzRecords/${user.uid}/${id}`);
      dbRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setSelectedRecordId(id);
          const record = snapshot.val();
          if (record.waypoints) {
            setWaypoints(record.waypoints);
            // Exclude current mission from DJI missions dropdown
            setMissions(missions.filter(mission => mission.id !== id));
          }
        }
      });
    }
  };
  
  // Usage example within your component
  useEffect(() => {
    waypoints.forEach(wp => {
      const position = new window.google.maps.LatLng(parseFloat(wp.coordinateLat), parseFloat(wp.coordinateLong));
      let rotationAngle = wp.waypointHeadingMode === 'towardPOI' ? calculatePOIAngle(wp, tempPoiMarker) : parseFloat(wp.waypointHeadingAngle);
      if (wp.waypointHeadingMode === 'smoothTransition') {
        rotationAngle = parseFloat(wp.waypointHeadingAngle);
      } else if (wp.waypointHeadingMode === 'towardPOI') {
        rotationAngle = calculatePOIAngle(wp, tempPoiMarker);
      }
      createRotatedMarker(position, rotationAngle);
    });
  }, [waypoints, map, tempPoiMarker]); // Include tempPoiMarker in dependency array

  const poiCoords = waypoints
  .filter(wp => wp.waypointPoiPointLat && wp.waypointPoiPointLong)
  .map((wp, index) => ({
    label: `POI ${index + 1}: ${wp.waypointPoiPointLat}, ${wp.waypointPoiPointLong}`,
    value: { lat: wp.waypointPoiPointLat, lng: wp.waypointPoiPointLong }
  }));

const renderWaypointsToXML = (waypoints) => {
    const header = `<?xml version="1.0" encoding="UTF-8"?>\n` +
                 `<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.2">\n` +
                 `<Document>\n` +
                 `<wpml:missionConfig>\n` +
                 `<wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>\n` +
                 `<wpml:finishAction>noAction</wpml:finishAction>\n` +
                 `<wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>\n` +
                 `<wpml:executeRCLostAction>hover</wpml:executeRCLostAction>\n` +
                 `<wpml:globalTransitionalSpeed>2.5</wpml:globalTransitionalSpeed>\n` +
                 `<wpml:droneInfo>\n` +
                 `<wpml:droneEnumValue>68</wpml:droneEnumValue>\n` +
                 `<wpml:droneSubEnumValue>0</wpml:droneSubEnumValue>\n` +
                 `</wpml:droneInfo>\n` +
                 `</wpml:missionConfig>\n` +
                 `<Folder>\n` +
                 `<wpml:templateId>0</wpml:templateId>\n` +
                 `<wpml:executeHeightMode>relativeToStartPoint</wpml:executeHeightMode>\n` +
                 `<wpml:waylineId>0</wpml:waylineId>\n` +
                 `<wpml:distance>0</wpml:distance>\n` +
                 `<wpml:duration>0</wpml:duration>\n` +
                 `<wpml:autoFlightSpeed>2.5</wpml:autoFlightSpeed>\n`;

    const footer = `\n` +
                `</Folder>\n` +
                 `</Document>\n` +
                 `</kml>\n`;
  
    const waypointsXML = waypoints.map(wp => {
        const poiPoint = wp.waypointPoiPoint || '0.000000,0.000000,0.000000';
        const gimbalParams = wp.actions.map(action => {
            let actionParams = '';
            if (action.actionGroupId === 1) {
                actionParams = 
                `<wpml:gimbalHeadingYawBase>${action.gimbalHeadingYawBase || 'aircraft'}</wpml:gimbalHeadingYawBase>\n` +
                `<wpml:gimbalRotateMode>${action.gimbalRotateMode || 'absoluteAngle'}</wpml:gimbalRotateMode>\n` +
                `<wpml:gimbalPitchRotateEnable>${action.gimbalPitchRotateEnable || '1'}</wpml:gimbalPitchRotateEnable>\n` +
                `<wpml:gimbalPitchRotateAngle>${action.gimbalPitchRotateAngle || '0'}</wpml:gimbalPitchRotateAngle>\n` +
                `<wpml:gimbalRollRotateEnable>${action.gimbalRollRotateEnable || '0'}</wpml:gimbalRollRotateEnable>\n` +
                `<wpml:gimbalRollRotateAngle>${action.gimbalRollRotateAngle || '0'}</wpml:gimbalRollRotateAngle>\n` +
                `<wpml:gimbalYawRotateEnable>${action.gimbalYawRotateEnable || '0'}</wpml:gimbalYawRotateEnable>\n` +
                `<wpml:gimbalYawRotateAngle>${action.gimbalYawRotateAngle || '0'}</wpml:gimbalYawRotateAngle>\n` +
                `<wpml:gimbalRotateTimeEnable>${action.gimbalRotateTimeEnable || '0'}</wpml:gimbalRotateTimeEnable>\n` +
                `<wpml:gimbalRotateTime>${action.gimbalRotateTime || '0'}</wpml:gimbalRotateTime>\n` +
                `<wpml:payloadPositionIndex>${action.payloadPositionIndex || '0'}</wpml:payloadPositionIndex>\n`;
            } else if (action.actionGroupId === 2) {
                actionParams = 
                `<wpml:gimbalPitchRotateAngle>${action.gimbalPitchRotateAngle || '0'}</wpml:gimbalPitchRotateAngle>\n` +
                `<wpml:payloadPositionIndex>${action.payloadPositionIndex || '0'}</wpml:payloadPositionIndex>\n`;
            }
            return (
                `<wpml:actionGroup>\n` +
                `<wpml:actionGroupId>${action.actionGroupId}</wpml:actionGroupId>\n` +
                `<wpml:actionGroupStartIndex>${action.actionGroupStartIndex}</wpml:actionGroupStartIndex>\n` +
                `<wpml:actionGroupEndIndex>${action.actionGroupEndIndex}</wpml:actionGroupEndIndex>\n` +
                `<wpml:actionGroupMode>${action.actionGroupMode}</wpml:actionGroupMode>\n` +
                `<wpml:actionTrigger>\n` +
                `<wpml:actionTriggerType>${action.actionTriggerType}</wpml:actionTriggerType>\n` +
                `</wpml:actionTrigger>\n` +
                `<wpml:action>\n` +
                `<wpml:actionId>${action.actionId}</wpml:actionId>\n` +
                `<wpml:actionActuatorFunc>${action.actionActuatorFunc}</wpml:actionActuatorFunc>\n` +
                `<wpml:actionActuatorFuncParam>\n` +
                actionParams +
                `</wpml:actionActuatorFuncParam>\n` +
                `</wpml:action>\n` +
                `</wpml:actionGroup>\n`
            );
        }).join('');
        
        return (
            `<Placemark>\n` + 
            `<Point>\n` +
            `<coordinates>\n${wp.coordinateLong},${wp.coordinateLat}\n</coordinates>\n` +
            `</Point>\n` +
            `<wpml:index>${wp.index}</wpml:index>\n` +
            `<wpml:executeHeight>${wp.executeHeight}</wpml:executeHeight>\n` +
            `<wpml:waypointSpeed>${wp.waypointSpeed}</wpml:waypointSpeed>\n` +
            `<wpml:waypointHeadingParam>\n` +
            `<wpml:waypointHeadingMode>${wp.waypointHeadingMode}</wpml:waypointHeadingMode>\n` +
            `<wpml:waypointHeadingAngle>${wp.waypointHeadingAngle}</wpml:waypointHeadingAngle>\n` +
            `<wpml:waypointPoiPoint>${poiPoint}</wpml:waypointPoiPoint>\n` +
            `<wpml:waypointHeadingAngleEnable>${wp.waypointHeadingAngleEnable}</wpml:waypointHeadingAngleEnable>\n` +
            `<wpml:waypointHeadingPathMode>${wp.waypointHeadingPathMode}</wpml:waypointHeadingPathMode>\n` +
            `</wpml:waypointHeadingParam>\n` +
            `<wpml:waypointTurnParam>\n` +
            `<wpml:waypointTurnMode>${wp.waypointTurnMode}</wpml:waypointTurnMode>\n` +
            `<wpml:waypointTurnDampingDist>${wp.waypointTurnDampingDist}</wpml:waypointTurnDampingDist>\n` +
            `</wpml:waypointTurnParam>\n` +
            `<wpml:useStraightLine>${wp.useStraightLine ? '1' : '0'}</wpml:useStraightLine>\n` +
            gimbalParams +
            `</Placemark>\n`
            );
        }).join('');

        return header + waypointsXML + footer;
    };

  return (
    <>
        <Form onSubmit={handleSubmit}>
            <Row className=" ">
                <Col md={12}>
                    <Card className="bg-light text-dark rounded shadow-sm mb-3">
                        <Card.Header>
                            <strong>Step 1.</strong> Choose how to get from <span className="text-success">Point A</span> to <span className="text-info">Point B</span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6} className="my-1">
                                    <h5>Waypoint Type/Turn Mode</h5>
                                    <InputGroup className="my-1">
                                        <ButtonGroup>
                                            {['1', '2', '3', '4'].map((buttonLabel, index) => (
                                                <Button
                                                    key={index}
                                                    variant={waypoint.waypointTurnMode === modeMapping[index] ? 'primary' : 'secondary'}
                                                    onClick={() => setWaypoint({ ...waypoint, waypointTurnMode: modeMapping[index] })}
                                                >
                                                    {buttonLabel}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                        <FormControl
                                            type="text"
                                            value={waypoint.waypointTurnMode.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                            disabled
                                            style={{ XmaxWidth: '300px' }} // Adjust the width as needed
                                            className="w-50"
                                            onChange={handleChange}
                                        />
                                    </InputGroup>                            
                                </Col>
                                <Col md={6} className="my-1">
                                    <h5>Waypoint Heading Mode</h5>
                                    <InputGroup className="my-1">
                                        <ButtonGroup>
                                            {['A', 'B', 'C', 'D', 'E'].map((label, index) => (
                                                <Button
                                                    key={label}
                                                    variant={waypoint.waypointHeadingMode === ['followWayline', 'manually', 'fixed', 'smoothTransition', 'towardPOI'][index] ? 'primary' : 'secondary'}
                                                    onClick={() => handleModeChange(['followWayline', 'manually', 'fixed', 'smoothTransition', 'towardPOI'][index])}
                                                >
                                                    {label}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                        <FormControl
                                                type="text"
                                                value={waypoint.waypointHeadingMode.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                disabled
                                                style={{ XmaxWidth: '300px' }} // Adjust the width as needed
                                                className="w-50"
                                                onChange={handleChange}
                                            />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>                           
                            <Row>
                                <Col md={6}>
                                    <strong style={{ fontSize: '1rem' }}>Waypoint Type/Turn Mode</strong>
                                    <ol style={{ fontSize: '0.75rem' }}>
                                        <li><strong>coordinateTurn</strong>: Coordinated turns, no dips, early turns.</li>
                                        <li><strong>toPointAndStopWithDiscontinuityCurvature</strong>: Fly in a straight line and the aircraft stops at the point.</li>
                                        <li><strong className="text-primary">toPointAndStopWithContinuityCurvature</strong>: Fly in a curve and the aircraft stops at the point.</li>
                                        <li><strong>toPointAndPassWithContinuityCurvature</strong>: Fly in a curve and the aircraft will not stop at the point.</li>
                                        <div style={{ fontSize: '0.75rem' }}><strong>Note</strong>: To use <strong>"Turns before waypoint. Flies through."</strong> mode from DJI Pilot2/FlightHub 2:</div>
                                    <ul style={{ fontSize: '0.75rem' }}>
                                        <li><strong>1）</strong>Set "Waypoint Type/Turn Mode" to "toPointAndPassWithContinuityCurvature".</li>
                                        <li><strong>2）</strong>Set the "Use Straight Line" to Enabled/Yes.</li>
                                    </ul>
                                    </ol>
                                </Col>
                                <Col md={6}>
                                    <div className="" style={{ fontSize: '0.75rem' }}>
                                        <strong style={{ fontSize: '1rem' }}>Waypoint Heading Mode</strong>
                                        <ol type="A">
                                            <li><strong>followWayline</strong>: The nose of the aircraft follows the course direction to the next waypoint.</li>
                                            <li><strong>manually</strong>: The user can manually control the nose orientation of the aircraft during the flight.</li>
                                            <li><strong>fixed</strong>: The nose of the aircraft maintains the yaw angle of the aircraft to the next waypoint.</li>
                                            <li><strong className="text-primary">smoothTransition</strong>: Customized. The target yaw angle for a waypoint is given and transitions evenly to the target yaw angle of the next waypoint. <i>Path Smoothing - "Figure 8" like pattern</i></li>
                                            <li><strong>towardPOI</strong>: The aircraft heading faces the point of interest.</li>
                                            <ul>
                                                <li><strong>Note</strong>: or you can select an existing POI from the dropdown list.</li>
                                            </ul>
                                        </ol>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="bg-light text-dark rounded shadow-sm mb-3">
                        <Card.Header>
                            <strong>Step 2.</strong> Click the map to set <span className="text-success">Waypoint Coordinates</span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                            <Col md={12}>
                                <h5>Waypoint Coordinates</h5>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="my-1">
                                        <InputGroup.Text>Lat.</InputGroup.Text>
                                        <FormControl
                                        type="number"
                                        step="0.000000"
                                        name="coordinateLat"
                                        value={waypoint.coordinateLat}
                                        onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="my-1">
                                        <InputGroup.Text>Long.</InputGroup.Text>
                                        <FormControl
                                        type="number"
                                        step="0.000000"
                                        name="coordinateLong"
                                        value={waypoint.coordinateLong}
                                        onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* <Col md={4} className="d-none">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Altitude</InputGroup.Text>
                                        <FormControl 
                                        type="number" 
                                        name="executeHeight" 
                                        value={waypoint.executeHeight} 
                                        onChange={handleChange} />
                                    </InputGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <InputGroup className="my-1">
                                        <InputGroup.Text>Alt.</InputGroup.Text>
                                        <FormControl 
                                        type="number" 
                                        name="executeHeight" 
                                        value={waypoint.executeHeight} 
                                        onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={3}>
                                    <InputGroup className="my-1">
                                        <InputGroup.Text>Speed</InputGroup.Text>
                                        <FormControl 
                                        type="number" 
                                        name="waypointSpeed" 
                                        value={waypoint.waypointSpeed} 
                                        onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="my-1">
                                        <InputGroup.Text>Heading</InputGroup.Text>
                                            <DropdownButton
                                            variant="outline-secondary"
                                            title={waypoint.waypointHeadingPathMode}
                                            onSelect={(e) => setWaypoint({ ...waypoint, waypointHeadingPathMode: e })}
                                            >
                                            <Dropdown.Item eventKey="clockwise">Clockwise</Dropdown.Item>
                                            <Dropdown.Item eventKey="counterClockwise">CounterClockwise</Dropdown.Item>
                                            <Dropdown.Item eventKey="followBadArc">Follow Bad Arc</Dropdown.Item>
                                            </DropdownButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {waypoint.waypointHeadingMode === 'towardPOI' && (
                                <Row className="Xpx-0 Xmx-0">
                                    <Col md={3} className="my-1">
                                        <DropdownButton
                                            id="dropdown-poi-coords"
                                            title="Previous POI"
                                            onSelect={(eventKey) => {
                                                const selectedPoiCoord = poiCoords[eventKey].value;
                                                setWaypoint({
                                                ...waypoint,
                                                waypointPoiPointLat: selectedPoiCoord.lat,
                                                waypointPoiPointLong: selectedPoiCoord.lng
                                                });
                                                // Optionally, set a temporary POI marker based on the selected coordinates
                                                setTempPoiMarker({ lat: parseFloat(selectedPoiCoord.lat), lng: parseFloat(selectedPoiCoord.lng) });
                                            }}
                                            >
                                            {poiCoords.map((coord, index) => (
                                                <Dropdown.Item key={index} eventKey={index}>
                                                {coord.label}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </Col>
                                    <Col>
                                        <InputGroup className="my-1">
                                        <InputGroup.Text>POI Lat.</InputGroup.Text>
                                        <FormControl type="text" name="waypointPoiPointLat" value={waypoint.waypointPoiPointLat} onChange={handleChange} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="my-1">
                                        <InputGroup.Text>POI Long.</InputGroup.Text>
                                        <FormControl type="text" name="waypointPoiPointLong" value={waypoint.waypointPoiPointLong} onChange={handleChange} />
                                        </InputGroup>
                                    </Col>
                                    {/* <Col md={4}>
                                        <InputGroup className="mb-3 d-none">
                                        <InputGroup.Text >Altitude</InputGroup.Text>
                                        <FormControl type="text" name="waypointPoiPointEle" value={waypoint.waypointPoiPointEle} onChange={handleChange} />
                                        </InputGroup>
                                    </Col> */}
                                    <Col className="my-1 d-none">
                                        <Button onClick={setPointOfInterestCord} variant="info" className="w-100">Set Point of Interest</Button>
                                    </Col>                                
                                </Row>
                            )}
                            <Row>
                                <Col md={3} className="d-none">
                                    <InputGroup className=" ">
                                        <InputGroup.Text>Index</InputGroup.Text>
                                        <FormControl 
                                        type="number"
                                        step="1" 
                                        name="index" 
                                        value={waypoint.index} 
                                        onChange={handleChange} 
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col md={4} className="px-0">
                                    <div className="" style={{ fontSize: '0.75rem' }}>
                                        <ul>
                                            <li><strong>Latitude</strong>: coordinate for North–South</li>
                                            <li><strong>Longitude</strong>: coordinate for East-West</li>
                                            <li><strong>Altitude</strong>: the height in meters</li>
                                            <li><strong>Speed</strong>: the speed in meters/second</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={8} className="px-0">
                                    <div className="" style={{ fontSize: '0.75rem' }}>
                                        <ul><li><strong>Heading Path Mode</strong>:<i> direction of rotation of the aircraft (yaw angle)</i></li>
                                        <ul>
                                            <li><strong>Clockwise</strong>: Follows the rotation of a clock</li>
                                            <li><strong>Counter Clockwise</strong>: Goes opposite the rotation of a clock</li>
                                            <li><strong className="text-primary">followBadArc</strong>: Rotation of the aircraft yaw angle along the shortest path.</li>
                                        </ul>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="bg-light text-dark rounded shadow-sm mb-3">
                        <Card.Header>
                            <strong>Step 3.</strong> Change <span className="text-success">Additional Settings</span> before <span className="text-info">Saving Waypoint</span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={8}>
                                    <InputGroup className=" ">
                                        <Col sm="4" className="px-0 my-1">
                                            <InputGroup.Text className=" ">Gimbal Pitch</InputGroup.Text>
                                        </Col>
                                        <Col sm="2" className="Xd-none pl-0 my-1">
                                            <Form.Control value={waypoint.gimbalPitchRotateAngle} name="gimbalPitchRotateAngle" onChange={handleChange}/>
                                        </Col>
                                        <Col sm="6" className="text-center px-2 pt-2 my-1">
                                            <RangeSlider
                                                name="gimbalPitchRotateAngle"
                                                value={waypoint.gimbalPitchRotateAngle}
                                                onChange={handleChange}
                                                min={-90}
                                                max={15}
                                                style={{ width: '100%'}}
                                                tooltip="off"
                                            />
                                        </Col>
                                    {/* <FormControl 
                                        type="number" 
                                        name="gimbalPitchRotateAngle" 
                                        value={waypoint.gimbalPitchRotateAngle} 
                                        onChange={handleChange}
                                    /> */}
                                    
                                    </InputGroup>
                                </Col>
                                {waypoint.waypointHeadingMode === 'smoothTransition' && (
                                    <>
                                        <Col md={4} className="my-1">
                                            <InputGroup>
                                            <InputGroup.Text>Yaw Angle</InputGroup.Text>
                                            <FormControl 
                                            type="number" 
                                            name="waypointHeadingAngle" 
                                            value={waypoint.waypointHeadingAngle} 
                                            min="0"
                                            max="359"
                                            onChange={handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                {/* waypoint.waypointHeadingMode === 'towardPOI' && (
                                    <InputGroup className="mb-3">
                                    <InputGroup.Text>Point of Interest</InputGroup.Text>
                                    <FormControl type="text" name="waypointPoiPoint" value={waypoint.waypointPoiPoint} onChange={handleChange} />
                                    </InputGroup>
                                )*/}

                                {
                                    ['coordinateTurn', 'toPointAndPassWithContinuityCurvature'].includes(waypoint.waypointTurnMode) && (
                                        <>
                                            <Col md={12} className="d-none"><h5>Coordinate Options</h5></Col>
                                            <Col md={4}>
                                                <InputGroup className="my-1 align-right">
                                                    <InputGroup.Text>Use Straight Line</InputGroup.Text>
                                                    <InputGroup.Checkbox name="useStraightLine" checked={waypoint.useStraightLine} onChange={handleChange} />
                                                </InputGroup>
                                            </Col>
                                            <Col md={8}>
                                                {['coordinateTurn', 'toPointAndPassWithContinuityCurvature'].includes(waypoint.waypointTurnMode) && waypoint.useStraightLine && (
                                                    <InputGroup className="my-1">
                                                        <InputGroup.Text>Turn Damping Distance</InputGroup.Text>
                                                        <FormControl type="number" name="waypointTurnDampingDist" value={waypoint.waypointTurnDampingDist} onChange={handleChange} />
                                                    </InputGroup>
                                                )}
                                            </Col>
                                        </>
                                    )
                                }
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row className="d-none">
                                <Col md={12}>
                                    <div className="" style={{ fontSize: '0.75rem' }}>
                                        <ul>
                                            <li><strong>Yaw Angle</strong>: compass heading to show aircraft rotation.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="py-1" style={{ fontSize: '0.75rem' }}>
                                        <strong>Straight Line</strong>&nbsp;<i>Whether the global segment trajectory is as close to a straight line as possible</i>
                                        <ul>
                                            <li><strong className="text-primary">No</strong>: Trajectory of the flight segment is a curve in the whole process</li>
                                            <li><strong>Yes</strong>: Trajectory of the flight segment should be as close as possible to the line connecting the two points</li>
                                            <li><strong>Yaw Angle</strong>: compass heading to show aircraft rotation.</li>
                                        </ul>
                                        <strong>Turn Damping Distance</strong>:
                                        <ul>
                                            <li>(min = 0; max = the maximum length of wayline segment)</li>
                                            <li>Note: The wayline segment between two waypoints should be greater than the sum of the turn intercepts of two waypoints. <span className="d-none">This element defines how far to the waypoint that the aircraft should turn.</span></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>                    
                </Col>
            </Row>
            <Row className="Xmb-3">
                <Col>
                    <Row>
                        <Col md="6">
                            <Button variant="success" className="mb-3 w-100" onClick={handleNewMission}>New Mission</Button>{' '}
                        </Col>
                        <Col md="6">
                            <Button variant="danger" className="mb-3 w-100" onClick={() => deleteWaypointMission(editIndex)}>Delete Mission</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Button variant="primary" className="mb-3 w-100" onClick={() => updateWaypointMission(currentMissionId, waypoints)}>Save Mission</Button>
                        </Col>
                        <Col md="6" className="">
                            <Form.Group controlId="missionName" className="mb-3">
                                {/* <Form.Label>Mission Name</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    value={missionName}
                                    onChange={(e) => setMissionName(e.target.value)}
                                    placeholder="mission name (defaults to my-mission)"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col md="6" className="d-none">
                            <Button variant="warning" className="w-100">Clear Waypoints</Button>{' '}
                        </Col>
                        <Col md="6">
                            <Form>
                                <Row>
                                    {fileContent && (    
                                        <Col md="2">
                                            <Button variant="primary" onClick={saveRecordToFirebase} className="mb-3">
                                                Save
                                            </Button>                                        
                                        </Col>
                                    )}
                                    <Col>
                                        <Form.Group controlId="formFile" className="mb-3 w-100">
                                            <Form.Control type="file" onChange={handleFileChange} accept=".kmz" title="Upload File" className="w-100"/>
                                        </Form.Group>
                                        {uploadError && <Alert variant="danger">{uploadError}</Alert>}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md="3">
                            <DropdownButton variant="secondary" id="dropdown-my-missions" title="My Missions" className="mb-3 w-100" style={{ width: '100% !important' }}>
                                {missions.map(mission => (
                                    <Dropdown.Item key={mission.id} onClick={() => setSelectedMissionId(mission.id)} className="w-100 btn btn-info" style={{ width: '100% !important' }}>
                                    {mission.missionName || 'Unnamed Mission'} - {new Date(mission.timestamp).toLocaleString()}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                        <Col md="3">
                            <DropdownButton variant="secondary" id="dropdown-missions" title="DJI Missions" className="mb-3 w-100" style={{ width: '100% !important' }}>
                                {Object.keys(records).map((id) => (
                                    <Dropdown.Item key={id} onClick={() => viewRecord(id)}>
                                    {records[id].missionName} - {new Date(records[id].timestamp).toLocaleString()}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col md="6">
                            <Button className="mb-3 w-100 shadow-sm" variant="success" type="submit">
                                {editIndex >= 0 ? "Apply Updates" : "Submit Waypoint"}
                            </Button>
                        </Col>
                        <Col md="6">
                            <Button className="mb-3 w-100 shadow-sm" onClick={resetForm} variant="warning">Reset Marker </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Row className="bg-secondary py-4">
                    {/* Progress Bar */}
                        <Col>
                            <div className="progress" style={{ height: '2rem' }}>
                                <div
                                    className={`progress-bar progress-bar-striped progress-bar-animated ${progressState === 'Completed - Confirm adding this waypoint' ? 'bg-success' : progressState === 'Waiting for Heading / Point of Interest - Click to Continue' ? 'bg-warning text-dark' : 'bg-danger'}`}
                                    role="progressbar"
                                    style={{
                                        // width: '100%',
                                        fontSize: '1rem',
                                        backgroundColor: progressState === 'Completed - Confirm adding this waypoint' ? '#28a745' : progressState === 'Waiting for Heading / Point of Interest - Click to Continue' ? '#ffc107' : '#dc3545',
                                        width: progressState === 'Completed - Confirm adding this waypoint' ? '100%' : progressState === 'Waiting for Heading / Point of Interest - Click to Continue' ? '70%' : '35%'
                                    }}
                                >
                                    {progressState}
                                </div>
                            </div>
                        </Col>

                    {showConfirmationPrompt && (
                    
                        <Col md={1}>
                            <div>
                                <div className="d-none">Press to Continue&nbsp;&nbsp;</div>
                                <Button variant="success" className="shadow-sm" onClick={handleSubmit}>Yes</Button>&nbsp;&nbsp;
                                <Button variant="danger" className="shadow-sm" onClick={resetForm}>No</Button>
                            </div>
                        </Col>
                    
                    )}
                    </Row>
                    <Row className="my-0 py-0">
                        {/* Google Maps LoadScript and MapContainer */}
                        {/* <LoadScript googleMapsApiKey='AIzaSyB1wqeNukTCD2oFuBdf7JG5as8g5Kd4a2s' >
                        <LoadScript googleMapsApiKey={process.env.mapsAPIKey}> */}
                        <LoadScript googleMapsApiKey='AIzaSyBqsEyt28eEY9dIYN2s59zIUzwn1gwYvF0' >
                            <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={currentLocation}
                            zoom={16}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            onClick={onMapClick}
                            options={{
                                mapTypeId: "terrain", // Set default map type (e.g., 'roadmap', 'satellite', 'hybrid', 'terrain')
                                mapTypeControl: true, // Enable the map type control for user selection
                                // zoomControl: false, // Disables the zoom control
                                // mapTypeControl: false, // Hides the map type control
                                scaleControl: false, // Hides the scale control
                                // streetViewControl: false, // Hides the Street View control
                                rotateControl: false, // Hides the rotate control
                                // fullscreenControl: false // Hides the fullscreen control
                                styles: mapStyles,
                                // ...other options
                            }}
                            >
                                {waypoints.map((wp, index) => (
                                    <Marker
                                    key={index}
                                    position={{ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) }}
                                    icon={renderMarkerIcon(wp)}
                                    // Add the onClick event if you need to handle marker clicks
                                    onClick={() => {
                                        setSelectedMarker({ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) });
                                        setMarkerInfo({ yawAngle: wp.waypointHeadingAngle, elevation: wp.executeHeight || 0 });
                                        }}
                                    />
                                ))}
                                {selectedMarker && (
                                    <InfoWindow
                                        position={selectedMarker}
                                        onCloseClick={() => setSelectedMarker(null)}
                                    >
                                        <div>
                                        <p>Yaw Angle/Heading: {markerInfo.yawAngle.toFixed(2)} degrees</p>
                                        <p>Alt Above Takeoff: {markerInfo.elevation.toFixed(2)} meters</p>
                                        {/* <p>Waypoint Elevation: {elevation !== null ? elevation.toFixed(2) + ' meters' : 'Loading...'}</p> */}
                                        </div>
                                    </InfoWindow>
                                )}
                                {missionWaypoints.map((wp, index) => (
                                    <Marker
                                        key={index}
                                        position={{ lat: parseFloat(wp.coordinateLat), lng: parseFloat(wp.coordinateLong) }}
                                        // Set additional marker properties as needed
                                    />
                                ))}
                                {tempPoiMarker && (
                                <Marker
                                    position={tempPoiMarker}
                                    icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    scaledSize: new window.google.maps.Size(32, 32)
                                    }}
                                />
                                )}
                                {waypoints.map((wp, index) => {
                                    const poiLat = parseFloat(wp.waypointPoiPointLat);
                                    const poiLng = parseFloat(wp.waypointPoiPointLong);
                                    if (poiLat && poiLng) {
                                    return (
                                        <Marker
                                        key={`poi-${index}`}
                                        position={{ lat: poiLat, lng: poiLng }}
                                        icon={{
                                            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // URL to a custom marker icon
                                            scaledSize: new window.google.maps.Size(32, 32) // Adjust size as needed
                                        }}
                                        // Optional: Add an onClick event for POI markers
                                        />
                                    );
                                    }
                                    return null;
                                })}
                                {newMarker && <Marker position={newMarker} icon={renderMarkerIcon(newMarker, true)} />}
                                {/* Child components, such as markers, info windows, etc. */}
                                {/* Polyline Component */}
                                <Polyline
                                path={getPath()}
                                options={{
                                    strokeColor: "#008000",
                                    strokeOpacity: 0.75,
                                    strokeWeight: 7,
                                    fillColor: "#008000",
                                    fillOpacity: 0.25,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    radius: 30000,
                                    zIndex: 1,
                                    icons: [
                                    {
                                        icon: {
                                        path: null, // google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        // path: 'google.maps.SymbolPath.FORWARD_OPEN_ARROW',
                                        scale: 2, // Adjust the size of the arrow
                                        strokeColor: '#0000FF', // Arrow color
                                        },
                                        offset: '100%', // Position at the end of each segment
                                        repeat: '100px', // Adjust the frequency of arrows
                                    },
                                    ],
                                }}
                                />
                            </GoogleMap>
                        </LoadScript>
                    </Row>
                </Col>
            </Row>
            
        
        </Form>

        <Row className="mb-3">
    <Col>
        <Table striped bordered hover className="mt-3">
            <thead>
                <tr>
                    <th className="">Index</th>
                    <th className="">Latitude</th>
                    <th className="">Longitude</th>
                    <th className="d-none d-lg-table-cell">Altitude</th>
                    <th className="d-none d-lg-table-cell">Speed</th>
                    <th className="d-none d-lg-table-cell">Heading Mode</th>
                    <th className="d-none d-lg-table-cell">Yaw Angle</th>
                    <th className="d-none d-lg-table-cell">Gimbal Pitch Angle</th>
                    <th className="d-none d-lg-table-cell">POI Point</th>
                    <th className="d-none d-lg-table-cell">Path Mode</th>
                    <th className="d-none d-lg-table-cell">Turn Mode</th>
                    <th className="d-none">Damping Dist</th>
                    <th className="d-none">Straight Line</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {waypoints.map((wp, index) => (
                <tr key={index}>
                <td className="">{wp.index}</td>
                <td className="">{wp.coordinateLat}</td>
                <td className="">{wp.coordinateLong}</td>
                <td className="d-none d-lg-table-cell">{wp.executeHeight}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointSpeed}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointHeadingMode}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointHeadingAngle}</td>
                <td className="d-none d-lg-table-cell">{wp.gimbalPitchRotateAngle}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointPoiPoint}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointHeadingPathMode}</td>
                <td className="d-none d-lg-table-cell">{wp.waypointTurnMode}</td>
                <td className="d-none">{wp.waypointTurnDampingDist}</td>
                <td className="d-none">{wp.useStraightLine ? 'Yes' : 'No'}</td>
                <td>
                    <Button variant="warning" size="sm" onClick={() => handleEdit(index)}>Edit</Button>
                    {' '}
                    <Button variant="danger" size="sm" onClick={() => handleDelete(index)}>Delete</Button>
                </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </Col>
</Row>

        <Row>
        <Col md={12}>
                <Row>
                    <Col md={6} className="mb-3">
                        <Card>
                            <Card.Header className="text-center">
                                Custom Waypoint Missions
                            </Card.Header>
                            <Card.Body>
                                <ListGroup id="my-missions">
                                        {missions.map(mission => (
                                            <ListGroup.Item key={mission.id} action onClick={() => setSelectedMissionId(mission.id)}>
                                                {mission.missionName || 'Unnamed Mission'} - {new Date(mission.timestamp).toLocaleString()}
                                                <Button variant="danger" size="sm" className="float-right" onClick={(e) => {e.stopPropagation(); deleteWaypointMission(mission.id);}}>Delete</Button>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                asdff
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Card>
                            <Card.Header className="text-center">
                                Uploaded DJI Missions
                            </Card.Header>
                            <Card.Body>
                                <ListGroup id="dji-missions">
                                    {Object.keys(records).map((id) => (
                                    <ListGroup.Item key={id} action onClick={() => viewRecord(id)}>
                                        {records[id].missionName} - {new Date(records[id].timestamp).toLocaleString()}
                                        <Button variant="danger" size="sm" className="" style={{ float: 'right' }} onClick={(e) => {e.stopPropagation(); deleteRecord(id);}}>Delete</Button>
                                    </ListGroup.Item>
                                ))}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                asdff
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <pre className="">
                    <Card className="bg-dark text-light bg-gradient">
                        <Card.Header>
                            Custom WPML (Waypoint Placemarkers)
                        </Card.Header>
                        <Card.Body className="bg-dark text-success">
                            {/* <code style={{ overflowY: 'scroll', maxHeight: '500px', minHeight: '320px' }}>
                                {renderCodeBlock()}
                            </code> */}
                            <code style={{ overflowY: 'scroll', maxHeight: '500px', minHeight: '320px' }}>
                                {renderWaypointsToXML(waypoints)}
                            </code>
                        </Card.Body>
                        <Card.Footer>
                            Copy and paste for now
                        </Card.Footer>
                    </Card>
                </pre>
            </Col>
            <Col md={6}>
                <pre className="">
                    <Card className="bg-dark text-light bg-gradient">
                        <Card.Header>
                            DJI WPML (Waypoint Placemarkers)
                        </Card.Header>
                        <Card.Body className="bg-dark text-success">
                            <code style={{ overflowY: 'scroll', maxHeight: '500px', minHeight: '320px' }}>
                                {fileContent && (
                                    <pre><code className="bg-dark text-success">{fileContent}</code></pre>
                                )}
                            </code>
                        </Card.Body>
                        <Card.Footer>
                            Copy and paste for now
                        </Card.Footer>
                    </Card>
                </pre>
            </Col>
        </Row>
    </>
  );
};

export default WaypointForm;