import React, { useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import './Compass.css'; // Assuming your styles are in App.css

export const Compass = () => {
  const [angle, setAngle] = useState(15);
  const [heading, setHeading] = useState(200);

  // Add your logic for updating angle and heading here

  return (
    <Container style={{ backgroundColor: 'transparent', padding: '20px' }}>
      {Number.isInteger(angle) && (
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <Card>
              <Card.Body>
                <Card.Title className=" ">Angle is: {angle}°</Card.Title>
                <div className="compassWrapper bg-dark">
                  <img
                    src='./assets/arrow.png' // Update the path as needed
                    className="arrow"
                    alt="Arrow"
                    style={{ transform: `rotate(${angle}deg)` }}
                  />
                  <img
                    src='./assets/compass_bg.png' // Update the path as needed
                    className="compassBg"
                    alt="Compass Background"
                    style={{ transform: `rotate(${-90 + heading}deg)` }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Compass;
