import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Alert, InputGroup, FormControl, DropdownButton, Dropdown, Table, Card, ButtonGroup, ListGroup } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function About() {
    return (
        <>
        <Container>
            <Row>
                <Col className="mx-auto text-center py-5 px-2">
                    <h1 className="display-4 fw-bold text-dark mb-4">Precision in Every Flight</h1>
                    <p className="lead text-secondary mb-4">Plan and execute complex drone flights with ease and accuracy using Waypoint Wizard.</p>
                    <center><img src="./logo192.png"></img></center>
                    <a href="waypoints" className="btn btn-primary btn-lg px-4 py-2 rounded">Get Started</a>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2 className="display-5 fw-bold text-center text-dark mb-4">Powerful Features</h2>
                </Col>
                <Col md={4}>
                    <div className="bg-white shadow p-4 rounded">
                        <h3 className="h5 fw-semibold mb-3">Interactive Mapping</h3>
                        <p className="text-secondary">Set waypoints and POIs directly on an intuitive map interface, simplifying the planning process.</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="bg-white shadow p-4 rounded">
                        <h3 className="h5 fw-semibold mb-3">Waypoint Customization</h3>
                        <p className="text-secondary">Control every aspect of your flight path with extensive waypoint parameters.</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="bg-white shadow p-4 rounded">
                        <h3 className="h5 fw-semibold mb-3">Mission Management</h3>
                        <p className="text-secondary">Create, edit, and store complex missions with real-time synchronization.</p>
                    </div>
                </Col>                
            </Row>
        </Container>
        
        <Row className="bg-light py-5">
            <Container className="">
                <Row>
                    <Col md={12}>
                        <h2 className="display-5 fw-bold text-center text-dark mb-4">What Our Users Say</h2>
                    </Col>
                    <Col md={6}>
                        <div className="bg-white shadow p-4 rounded">
                            <blockquote className="text-secondary fst-italic">"Waypoint Wizard transformed how we conduct our aerial surveys. The accuracy and ease of use are unparalleled."</blockquote>
                            <div className="mt-3">
                                <p className="fw-semibold">- Alex Johnson, Geospatial Analyst</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="bg-white shadow p-4 rounded">
                            <blockquote className="text-secondary fst-italic">"As a hobbyist, this tool made planning my drone flights a breeze, especially with the intuitive map interface."</blockquote>
                            <div className="mt-3">
                                <p className="fw-semibold">- Sarah Lee, Drone Enthusiast</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
        <Row className="bg-primary text-white">
            <Container>
                <Col className="mx-auto text-center py-5 px-2">
                    <h2 className="display-5 fw-bold mb-4">Ready to Elevate Your Drone Experience?</h2>
                    <p className="lead mb-4">Join Waypoint Wizard today and take your drone missions to new heights.</p>
                    <a href="login" className="btn btn-light btn-lg px-4 py-2 rounded text-primary">Sign Up Now</a>
                </Col>
            </Container>
        </Row>
        </>

    )
}